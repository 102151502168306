@font-face {
  font-family: 'Salesforce Sans';
  src:
    url('https://c1.sfdcstatic.com/etc/clientlibs/sfdc-aem-master/clientlibs_base/fonts/SalesforceSans-Regular.woff')
      format('woff'),
    url('https://c1.sfdcstatic.com/etc/clientlibs/sfdc-aem-master/clientlibs_base/fonts/SalesforceSans-Regular.ttf')
      format('truetype');
}
.embeddedServiceHelpButton .helpButton .uiButton {
  background-color: #ff9d0b !important;
  font-family: 'Salesforce Sans', sans-serif !important;
}
.embeddedServiceHelpButton .helpButton .uiButton:focus {
  outline: 1px solid #ff9d0b !important;
}
.embeddedServiceSidebarHeader .headerText {
  margin: auto !important;
}
.embeddedServiceSidebarHeader .content,
.embeddedServiceSidebarMinimizedDefaultUI .content {
  min-height: 0 !important;
}
.embeddedServiceSidebarHeader .headerItem:focus:after,
.embeddedServiceSidebarHeader .headerButton:focus:after {
  width: 60% !important;
  height: 60% !important;
}

.embeddedServiceLiveAgentStateChatInputFooter .footerMenuWrapper {
  display: none;
}
