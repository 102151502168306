@import 'styles/base/settings.colors';

.service-tile {
  padding: 0 15px 15px 0em;

  &:last-child {
    padding-right: 0;
  }

  .tile-url {
    text-decoration: none;
  }

  .tile {
    padding: 1em 1em 2em 1em;
    height: 10.5em;
    width: 10.6em;
    border-radius: 11px;
    background-color: $color-white;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(179, 180, 181, 0.2);
    text-align: center;

    .tile-icon {
      padding-top: 0.75em;
      height: 5.65em;
      width: 8.25em;
      margin: 0 auto;
    }

    .tile-name {
      color: $color-bondi-blue;
      margin-top: -2em;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }

    &:hover {
      box-shadow: 1px 2px 6px 1px rgba(0, 0, 0, 0.5);
    }
  }

  @media screen and (min-width: 579px) and (max-width: 660px) {
    &:nth-child(3n) {
      padding-right: 0;
    }
  }

  @media screen and (max-width: 563px) {
    &:nth-child(2n) {
      padding-right: 0;
    }
  }

  @media screen and (max-width: 394px) {
    padding-right: 0;
    width: calc((100% - 15px) / 2);

    .tile {
      width: 100%;

      .tile-icon {
        margin: 0 auto;
        width: 100%;
      }

      .tile-name {
        font-size: 4vw;
      }
    }
  }
}
