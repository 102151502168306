/*******************************
         Site Overrides
*******************************/

.ui.button:last-child,
.ui.button:only-child {
  margin-right: 0px;
}

.ui.basic.button {
  font-weight: 600;
  color: #34495e !important;
}

.ui.basic.button > .icon.outline:not(.button) {
  opacity: 1;
  height: auto;
  display: inline-block;
  font-size: 1.3em;
}

.ui.basic.button:not(.icon) > .icon.outline:not(.button):not(.dropdown) {
  margin: 0;
  margin-right: 12px;
  transform: translateY(2px);
}
