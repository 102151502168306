.dashboard-wrapper {
  display: flex;
  height: 100%;

  @media screen and (min-width: 666px) {
    margin-left: 16px;
    margin-right: 16px;
  }

  .body-left {
    width: 300px;
    min-width: 300px;
    padding: 88px 20px 0;
  }

  .body-right {
    margin: 32px 20px 0;

    .welcome {
      margin: 0 20px;
      font-size: $font-size-header;
      font-weight: bold;
      padding-bottom: 10px;
    }

    @media screen and (max-width: $breakpoint-dashboard) {
      .welcome {
        margin-left: 23px;
      }
    }

    .body-content {
      display: flex;
      align-items: flex-start;

      @media screen and (max-width: $breakpoint-dashboard) {
        flex-wrap: wrap;
      }
    }
  }

  @media screen and (max-width: $breakpoint-dashboard) {
    flex-wrap: wrap;

    .body-left {
      order: 1;
      width: 100%;
      margin-left: 0;
      padding: 20px 20px 0;
    }

    .body-right {
      order: 0;
      width: 100%;
      min-width: 0;
    }
  }
}
