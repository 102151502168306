/* ------------------------------
  DEFAULTS
------------------------------ */

/* Font Sizing */
$font-size-base: 16px;
$font-size-xl: 24px;
$font-size-lg: 20px;
$font-size-md: 16px;
$font-size-sm: 14px;
$font-size-xs: 12px;

$font-size-header: 32px;

/* Base Page */
$base-page-width: 1024px;
$min-page-width: 400px;

/* Responsive Breakpoints */
$breakpoint-login-forms: 582px;
$breakpoint-header: 550px;
$breakpoint-footer: 600px;
$breakpoint-base-page: 665px;
$breakpoint-dashboard: 665px;
$breakpoint-account-page: 750px;
$breakpoint-alerts: 850px;
$breakpoint-alerts-sm: 400px;
