/* ------------------------------
  COLORS
------------------------------ */

// Naming suggestions: http://www.color-blindness.com/color-name-hue/

$base-background-color: #f3f4f6;
$color-teachstone-blue: #008fbf;

$color-alice-blue: #f5fbfe;
$color-aquamarine: #00a478;
$color-black: #000000;
$color-bondi-blue: #008fbf;
$color-brown: #2b2b2b;
$color-cavern-pink: #e2b2b2;
$color-cello: #364a5e;
$color-cerulean: #02608f;
$color-contessa: #c56767;
$color-dark-blue: #1d8ebf;
$color-dark-green: #4e5a56;
$color-egyptian-blue: #135b94;
$color-error: #d81915;
$color-gainsboro: #d9d9d9;
$color-ghost: #bcbdc1;
$color-gray21: #363636;
$color-gray80: #cccccc;
$color-gray81: #c5c5c5;
$color-gray82: #e2e2e2;
$color-gray85: #dcdcdc;
$color-gray90: #bfe7dc;
$color-gull-grey: #b3b4b5;
$color-iris-blue: #11a0d0;
$color-jungle-green: #27a46f;
$color-lavender: #f6fbfe;
$color-light-ghost: #b1e1d4;
$color-light-grey: #c9c9c9;
$color-light-sd-grey: #dddddd;
$color-madison: #2c3e50;
$color-madison-blue: #2f84b7;
$color-nero: #2b2b2b;
$color-red: #ff0000;
$color-regent-blue: #a9cddc;
$color-salt-box: #5c5b5c;
$color-shady-lady: #abaaab;
$color-silver: #bdbdbd;
$color-summer-sky: #2d9ad5;
$color-white: #ffffff;
$color-zircon: #e8e9eb;
