@import '../../styles/base/settings.colors';

.RichTextEditor {
  // React Quill Customizations
  .ql-editor {
    resize: vertical;
    min-height: 150px;
    padding: 11px 14px;
    background-color: $color-white;

    strong {
      font-weight: bold;
    }

    ul,
    ol {
      padding-left: 0;
    }

    ul > li:before {
      content: '●';
    }

    ol > li:before {
      font-weight: bold;
      font-size: 1em;
    }

    u {
      text-decoration: underline;
    }
  }

  .ql-toolbar {
    background-color: $color-white;
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-radius: 4px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .ql-container {
    line-height: 1.5;
    font-size: 1em;
    font-weight: 300;
    font-family: inherit;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    border: 1px solid rgba(34, 36, 38, 0.15);
    min-height: 150px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &.RichTextEditor--readOnly {
    .ql-toolbar {
      display: none;
    }

    .ql-container {
      border: 0;
      height: auto;
      min-height: 0;
    }

    .ql-editor {
      padding: 0;
      background-color: transparent;
      resize: none;
      min-height: 0;
    }
  }

  &.RichTextEditor--error {
    .ql-container,
    .ql-toolbar,
    .ql-editor {
      color: #9f3a38;
      border-color: #e0b4b4;
      background-color: #fff6f6;
    }
  }
}
