/* ------------------------------
  BASE STYLES
------------------------------ */

html {
  box-sizing: border-box;
  background-color: $base-background-color;
  color: $color-cello;
  font-family: proxima-nova, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: $font-size-base;
  line-height: 1.5;
  height: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  height: 100%;
  color: #34495e;
}

#app-wrapper,
#app-wrapper > .users-page-wrapper {
  height: 100%;
}
