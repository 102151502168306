/*******************************
         Site Overrides
*******************************/

i.icon.circular {
  border: 0.1em solid rgba(0, 0, 0, 0.1);
  border-radius: 50% !important;
  box-shadow: none !important;
  width: 2.2em !important;
  height: 2.2em !important;
}

i.icon.outline {
  outline: 0 !important;
}
