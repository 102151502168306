@media screen and (min-width: 481px) {
  .Toastify__toast-container {
    width: 360px;
  }
}

.Toastify__toast {
  border-radius: 6px;
  border: 1px solid #e5e7eb;
}

.Toastify__close-button {
  margin-top: 6px;
  margin-right: 6px;
}
